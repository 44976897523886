import React from "react"
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3"
import { Helmet } from "react-helmet"
export const wrapRootElement = ({ element }) => {
  return (
    <GoogleReCaptchaProvider reCaptchaKey="6LfcLlceAAAAAPG_M_R66vcbIs2pzPbVLYeYs5PE">
      {/* <CookieBot domainGroupId="eff4e514-217f-4fe8-bd10-0513f9bfb6a3" /> */}
      <Helmet>
        <script
          async
          src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-6696390054962545"
          crossorigin="anonymous"
        ></script>
        <script>
          {`window.axeptioSettings = {
  clientId: "620cf7187fe343da0cf547a3",
  cookiesVersion: "sebastiengil,com-fr",
};
 
(function(d, s) {
  var t = d.getElementsByTagName(s)[0], e = d.createElement(s);
  e.async = true; e.src = "//static.axept.io/sdk.js";
  t.parentNode.insertBefore(e, t);
})(document, "script");`}
        </script>
      </Helmet>
      {element}
    </GoogleReCaptchaProvider>
  )
}
